import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useLottie, useLottieInteractivity } from 'lottie-react'
import bottomLeft from './trims/bottomLeft'
import bottomRight from './trims/bottomRight'
import topLeft from './trims/topLeft'
import topRight from './trims/topRight'

const TrimPath = ({ name, className }) => {
    const paths = {
        bottomLeft: bottomLeft,
        bottomRight: bottomRight,
        topRight: topRight,
        topLeft: topLeft,
    }

    const config = {
        animationData: paths[name],
        loop: false,
        autoplay: false,
    }

    const lottieObj = useLottie(config)

    const Animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        actions: [
            {
                visibility: [0.1, 1],
                type: 'seek',
                frames: [1, 74],
            },
        ],
    })

    return (
        <div
            className={cx(
                'z-50',
                {
                    'absolute -top-20 -left-10': name === 'topLeft',
                    'absolute -top-60 -right-10': name === 'topRight',
                    'absolute -bottom-10 -left-6': name === 'bottomLeft',
                    'absolute bottom-0 -right-10': name === 'bottomRight',
                    '': name === 'footer',
                },
                className,
                'pointer-events-none select-none'
            )}
        >
            {Animation}
        </div>
    )
}

export default TrimPath

TrimPath.propTypes = {
    name: PropTypes.string,
}
